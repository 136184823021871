button.mat-stroked-button.mat-primary {
  border-color: var(--primary);
}

button.mat-stroked-button.mat-accent {
  border-color: var(--accent);
}

button.mat-stroked-button.mat-warn {
  border-color: var(--warn);
}

button.mat-raised-button {
  box-shadow: none !important;
}

button.mat-raised-button.ordinary {
  background: #e5e5e5;
  color: white;
}
