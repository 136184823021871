/* You can add global styles to this file, and also import other style files */
@forward 'css-customized/mat-buttons.css';
@forward 'css-customized/mat-form.css';
@forward 'css-customized/headlines.css';

@layer bootstrap, material, base, app, component;

@font-face {
  font-family: OpenSans;
  src: url(../assets/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  src: url(../assets/fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-style: italic;
  src: url(../assets/fonts/OpenSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  font-style: italic;
  src: url(../assets/fonts/OpenSans-BoldItalic.ttf) format('truetype');
}

@layer material {
  * {
    font-family: OpenSans, Arial;
  }
}

html {
  height: 100%;
  max-height: -webkit-fill-available;
}

body {
  margin: 0;
  overflow: hidden;
  height: 100%;
}

@media(min-width: 992px) {
  body {
    max-height: 100vh;
    min-height: -webkit-fill-available;
    box-sizing: border-box;
  }
}

@layer app {
  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

mat-paginator {
  font-size: 12px;
  background: transparent;

  .mat-paginator-container {
    margin: 0;
    padding: 0;
    min-height: 45px;
    justify-content: flex-start;
  }

  .mat-paginator-page-size {
    display: none !important;
  }

  // Paginator Buttons
  span.mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Navigation First
  .mat-paginator-navigation-first {
    order: 1;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }

  // Navigation Previous
  .mat-paginator-navigation-previous {
    order: 2;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }

  // Range Label
  .mat-paginator-range-label {
    width: 80px;
    color: #414141;
    order: 3;
    text-align: center;
  }

  // Navigation Next
  .mat-paginator-navigation-next {
    order: 4;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }

  // Navigation Last
  .mat-paginator-navigation-last {
    order: 5;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }
}

mat-form-field {
  button {
    &.mat-icon-button:enabled[mat-icon-button] {
      color: currentColor !important;
    }
  }
}

button {

  &.mat-button,
  &.mat-raised-button,
  &.mat-flat-button {
    box-shadow: none !important;
  }

  &.mat-button:enabled,
  &.mat-raised-button:enabled,
  &.mat-flat-button:enabled {
    &:hover,
    &:focus {
      background-color: var(--default-hover) !important;

      &[color="accent"] {
        background-color: var(--accent-hover) !important;
      }

      &[color="primary"] {
        background-color: var(--primary-hover) !important;
      }
    }
  }

  &.mat-stroked-button:enabled,
  &.mat-icon-button:enabled {
    &[mat-icon-button] {
      color: var(--accent) !important;
    }

    &:hover,
    &:focus {
      border-color: var(--accent-hover) !important;
      color: var(--accent-hover) !important;

      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }
}

.mdc-checkbox__checkmark {
  color: white !important;
}
